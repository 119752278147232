import { template as template_ae3ca938eb46477ca618f679ee94522d } from "@ember/template-compiler";
const FKControlMenuContainer = template_ae3ca938eb46477ca618f679ee94522d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
