import { template as template_586a8a2dbe1247b2a0137095d361c89a } from "@ember/template-compiler";
const WelcomeHeader = template_586a8a2dbe1247b2a0137095d361c89a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
